import { graphql, PageProps } from "gatsby"
import React from "react"
import "twin.macro"

import { Article, BaseLayer, Headings, Section, Typography, Wrapper } from "@/atoms"
import { BreadCrumbs, Layout, NextSEO, SEO } from "@/components"
import { NewsPostQuery } from "@/gatsby-graphql"
import { formatDate } from "@/utils"
import tw from "twin.macro"

const NewsPost: React.FC<PageProps<NewsPostQuery>> = ({ data, location, pageContext }) => {
  const { news: newsPost, date, modified } = data.wpNews
  return (
    <Layout>
      {/* <SEO title={newsPost.newsTitle} /> */}
      <NextSEO
        type="NewsArticle"
        title={newsPost.newsTitle}
        description={newsPost.newsTitle}
        url={`/news/${newsPost.newsTitle}`}
        section={newsPost.newsCategoryAcf?.name ? newsPost.newsCategoryAcf?.name : "お知らせ"}
        datePublished={date}
        dateModified={modified}
        breadCrumbs={[
          { title: "更新情報", uri: "/news" },
          { title: newsPost.newsTitle },
        ]}
      />
      <Article css={{
        ...tw`space-y-12`,
      }}>
        <Section bg={'tertiary'} >
          <Wrapper
            // center
            maxWidth={'tablet'}
            py='6'
            px='4'
            css={{ ...tw`space-y-2` }}
          >
            <BreadCrumbs
              breadCrumbs={[
                { title: "更新情報", uri: "/news" },
                { title: newsPost.newsTitle },
              ]}
            />
            <Headings variant="h2" as="h1">{newsPost.newsTitle}</Headings>
            <div tw="text-xs tablet:(text-sm)">
              <time>
                {formatDate(newsPost.newsDate, "YYYYMMDD", "YYYY年M月D日", true)}
                {" | "}
              </time>
              <span tw="">{newsPost.newsCategoryAcf?.name ? newsPost.newsCategoryAcf?.name : "お知らせ"}</span>
            </div>
          </Wrapper>
        </Section>
        <section>
          <Wrapper
            maxWidth={'tablet'}
            px='4'
          >
            <Typography prose={'base'} accent="blue"
              dangerouslySetInnerHTML={{ __html: newsPost.newsText }}
            />
          </Wrapper>
        </section>
      </Article>
    </Layout>
  )
}

export default NewsPost

export const pageQuery = graphql`
  query NewsPost($databaseId: Int!, $nextId: Int, $prevId: Int) {
    wpNews(databaseId: { eq: $databaseId }) {
      databaseId
      uri
      date
      modified
      news {
        newsDate
        newsTitle
        newsText
        newsCategoryAcf {
          name
        }
      }
    }
    nextNews: wpNews(databaseId: { eq: $nextId }) {
      uri
      news {
        newsDate
        newsTitle
        newsText
        newsCategoryAcf {
          name
        }
      }
    }
    prevNews: wpNews(databaseId: { eq: $prevId }) {
      uri
      news {
        newsDate
        newsTitle
        newsText
        newsCategoryAcf {
          name
        }
      }
    }
  }
`
